import CookieConsent from '@/components/CookieConsent';
import { StateContext } from '@/context/StateContext';
import { useSessionTimeout } from '@/hooks/useSessionTimeout';
import Cookies from 'js-cookie';
import { SessionProvider } from 'next-auth/react';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import RootLayout from '../src/app/layout';
import '../styles/main.scss';
import '../styles/normalize.css';

// Create a new component to handle session timeout
function SessionTimeoutWrapper({ children }) {
  useSessionTimeout();
  return children;
}

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const [cookieConsent, setCookieConsent] = useState(null);

  useEffect(() => {
    // Read the cookie on mount (only runs on client)
    const consent = Cookies.get('cookie_consent');
    setCookieConsent(consent);
  }, []);

  return (
    <>
      {cookieConsent === 'accepted' && (
        <>
          <Script
            strategy="afterInteractive"
            src="https://www.googletagmanager.com/gtag/js?id=G-PHH0JLNSMW"
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-PHH0JLNSMW');
              `,
            }}
          />
        </>
      )}

      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="icon"
          href="/images/favicon.ico"
          sizes="48x48 96x96 144x144 256x256"
          type="image/x-icon"
        />
        <link rel="icon" href="/images/favicon-192.png" sizes="192x192" type="image/png" />
        <link rel="apple-touch-icon" href="/images/apple-touch-192.png" />
        <link rel="icon" href="/images/favicon-512.png" sizes="512x512" type="image/png" />
      </Head>
      <SessionProvider session={session}>
        <StateContext>
          <SessionTimeoutWrapper>
            <div className="toast-container">
              <Toaster
                toastOptions={{
                  className: '',
                  duration: 5000,
                  style: {
                    background: '#363636',
                    color: '#fff',
                  },
                }}
              />
            </div>
            <RootLayout>
              <Component {...pageProps} />
            </RootLayout>
          </SessionTimeoutWrapper>
        </StateContext>
      </SessionProvider>
      <CookieConsent onConsentChange={(value) => setCookieConsent(value)} />
    </>
  );
}

export default MyApp;
