export const products = [
  {
    id: 'stripe-test-product',
    name: 'Stripe Test Product',
    title: 'Stripe Test Product',
    optionText: 'Test product for Stripe integration',
    abbreviation: 'TEST',
    abbrev: 'test',
    type: 'test',
    shortDescription: 'Test Product: $1 for Stripe testing',
    digiCertType: 'test_product',
    digiCertEndpoint: 'test',
    subtext:
      'This is a test product for validating Stripe integration in production. It costs only $1.',
    bullets: ['Test Stripe integration', 'Minimal cost ($1)', 'Safe for production testing'],
    description: 'A test product that costs $1 for validating Stripe integration in production.',
    learnMoreLink: '/products/stripe-test-product',
    basePricePerDomain: 1,
    additionalDomainPrice: 0,
    discountOffBase: 0,
    discountOffSAN: 0,
    billingPeriod: 'one-time',
    msrp: 1,
    yearMultipliers: [1],
    image: '/images/BM-Icon-VMC.png',
    icon: '/images/bm-symbol-with-check.svg',
    features: [
      { name: 'Test', type: 'counter', default: 1 },
      'Test Product',
      'For Stripe Testing Only',
    ],
    forWho: ['Developers', 'Testing purposes only'],
    required: ['None'],
    marketingText: 'This is a test product for validating Stripe integration.',
    marketingBullets: ['Test product type', 'Costs only $1', 'For testing purposes'],
  },
  {
    id: 'verified-mark-certificate',
    name: 'Verified Mark Certificate',
    title: 'Verified Mark Certificate',
    optionText: 'My logo is a registered trademark',
    abbreviation: 'VMC',
    abbrev: 'vmc',
    type: 'certificate',
    shortDescription: 'VMC Type: Registered Mark',
    digiCertType: 'registered_mark',
    digiCertEndpoint: 'vmc_basic',
    subtext:
      'Showcase your logo and a verified blue checkmark on every email you send. With our Verified Mark Certificate (VMC) from DigiCert, you’ll be able to create a trusted email & immersive brand experience that converts more business. Adding a VMC is the final step to maximizing your DMARC enforcement to protect against phishing while boosting brand recognition and trust within the most popular mailboxes. Your customers deserve to know they’re receiving authentic email communications from you—VMC does exactly that! Since your logo is a registered trademark, you’ll breeze through logo validation to quickly deliver a more trusted & personalized experience for all. And Brandymail™ is here to fast-track it.',
    bullets: [
      'Increase email open rates',
      'Boost email engagement',
      'Enhance brand experience',
      'Protect against phishing',
      'Improve email delivery',
      'Increase purchase likelihood',
      'Add credibility to emails',
      'Differentiate from competitors',
      'Distance yourself from bad-actors',
    ],
    description:
      'A VMC will display the logo of your company in popular inboxes and a blue checkmark in Gmail for officially registered trademarks or government agencies.',
    learnMoreLink: '/products/digicert-verified-mark-certificate',
    basePricePerDomain: 1608,
    additionalDomainPrice: 1608,
    discountOffBase: 0.1,
    discountOffSAN: 0.1,
    billingPeriod: 'year',
    msrp: 1608,
    yearMultipliers: [1, 1.9, 2.75],
    image: '/images/BM-Icon-VMC.png',
    icon: '/images/bm-symbol-with-check.svg',
    features: [
      { name: 'Logo', type: 'counter', default: 1 },
      { name: 'Domain', type: 'counter', default: 1 },
      { name: 'Year', type: 'counter', default: 1, max: 3 },
      'DigiCert Verified Mark Certificate',
      'Verified Blue Checkmark in Gmail',
      'Premium Support',
      'Management Portal',
      'Premium Tools',
    ],
    forWho: ['Any company with a trademarked logo', 'Government agencies'],
    required: [
      'Logo is a registered trademark or government seal',
      'BIMI-compliant domain',
      'SVG formatted logo',
    ],
    marketingText:
      'This DigiCert Registered Mark VMC enables all the visual cues for email recipients to quickly identify that you’re a trusted & verified sender. Marketing teams leverage the ability to certify email to take back control of the inbox once and for all. With this Registered Mark VMC, you’ll replace the generic initials in the avatar slot with your trademarked logo and add the coveted blue checkmark. To do this, you’ll need your IT team to enforce stricter email security to unlock BIMI for your domain(s). They’ll first need to set up SPF, DKIM and enforce DMARC policies to ensure proper email delivery. Once complete, everyone will be on their way to reducing spoofing & phishing attacks, elevating your brand and protecting your customers and prospects like never before. The advantages of verified mark certificates are undeniable. Here are some product features:',
    marketingBullets: [
      'VMC type: Registered Mark',
      'Display trademarked company logo',
      'Activate blue checkmark in Gmail',
      '1 verified logo per certificate',
      'DigiCert hosted SVG logo (optional)',
      'Add up to 250 domains (extra fee per domain)',
      'DMARC required: p=reject or quarantine at 100%',
      'BIMI-compliant certificate',
      'High-assurance business validation included',
      'Supported by Gmail, Microsoft, Yahoo, etc.',
      'Unlimited certificate reissues',
      'Certificate management portal included',
      '24/7/365 support',
    ],
    marketingCallout1Title: 'Increase in Open Rates',
    marketingCallout1Text: [
      'By showcasing your authenticity, you’ll create more trust. More trust in your customer’s email experience equals more opens. Studies show more than a 21% increase in open rates with Mark Certificates.',
    ],
    marketingCallout2Title: 'Increase in Brand Recognition',
    marketingCallout2Text:
      'Capitalizing on the avatar real estate to display your brand brings a significant increase in brand recognition. Studies show more than an 18% increase in brand recall after just a five-second exposure.',
    marketingCallout3Title: 'Increase in Email Deliverability',
    marketingCallout3Text:
      'Getting a VMC means you implemented the best email security practices. This can result in a 10% boost in email deliverability. Which simply means more opens and recognition.',
    landing: {
      subtext:
        'I’m sure we can all agree, inboxes are complete chaos these days! Luckily, Verified Mark Certificates (VMC) are here to give us our sanity back. With a VMC for Registered Marks, you can now showcase your logo and a blue checkmark on every email you send to increase open rates.',
      subtext2:
        'With our VMCs from DigiCert, you’ll be able to create a trusted email & immersive brand experience that simply converts more business. Adding a VMC protects against phishing while boosting brand recognition and trust within popular mailboxes. Your customers deserve to know they’re receiving authentic email communications from you—VMC does exactly that! Since your logo is a registered trademark, you’ll breeze through logo validation to quickly deliver a more trusted & personalized experience for all. And Brandymail™ is here to fast-track it and help you navigate the complex process.',
      marketingText:
        'This DigiCert Registered Mark VMC enables all the visual cues for email recipients to quickly identify that you’re a trusted & verified sender. Marketing teams leverage the ability to certify email to take back control of the inbox once and for all. With this Registered Mark VMC, you’ll replace the generic initials in the avatar slot with your trademarked logo and add the coveted blue checkmark...',
      marketingCallout4Title: 'Increase in Trust',
      marketingCallout4Text:
        'Align your brand with the global leader in digital trust. By relying on DigiCert to assert your identity, authenticity, and trust, you’ll be making a statement that only the #1 trust provider can say.',
    },
  },
  {
    id: 'modified-registered-mark',
    name: 'Common Mark Certificate',
    title: 'Common Mark Certificate',
    optionText: 'My logo is a registered trademark, BUT I’ve modified it',
    abbreviation: 'MRM',
    abbrev: 'mrm',
    type: 'certificate',
    shortDescription: 'CMC Type: Modified Registered Mark',
    digiCertType: 'mark_certificate',
    digiCertEndpoint: 'mark_certificate',
    slug: 'digicert-modified-registered-mark',
    subtext:
      'Showcase your logo on every email you send. With our Verified Mark Certificate (VMC) from DigiCert, you’ll be able to create a trusted email & immersive brand experience that converts more business. Adding a VMC is the final step to maximizing your DMARC enforcement to protect against phishing while boosting brand recognition and trust within the most popular mailboxes. Your customers deserve to know they’re receiving authentic email communications from you—VMC does exactly that! And Brandymail™ is here to fast-track it.',
    bullets: [
      'Increase email open rates',
      'Boost email engagement',
      'Enhance brand experience',
      'Protect against phishing',
      'Improve email delivery',
      'Increase purchase likelihood',
      'Add credibility to emails',
      'Differentiate from competitors',
      'Distance yourself from bad-actors',
    ],
    description:
      'A CMC will display the logo of your company in popular inboxes and does not require a registered trademark.',
    learnMoreLink: '/products/digicert-common-mark-certificate',
    basePricePerDomain: 1188,
    additionalDomainPrice: 1188,
    discountOffBase: 0.1,
    discountOffSAN: 0.1,
    billingPeriod: 'year',
    msrp: 1188,
    yearMultipliers: [1, 1.9, 2.75],
    image: '/images/BM-Icon-CMC.png',
    icon: '/images/bm-symbol-with-no-check.svg',
    features: [
      { name: 'Logo', type: 'counter', default: 1 },
      { name: 'Domain', type: 'counter', default: 1 },
      { name: 'Year', type: 'counter', default: 1, max: 3 },
      'DigiCert Common Mark Certificate',
      'Premium Support',
      'Management Portal',
      'Premium Tools',
    ],
    forWho: [
      'Any company with a prior use logo',
      'A company wanting to use a modified version of their trademarked logo',
    ],
    required: ['Logo in use for at least 1 year', 'BIMI-compliant domain', 'SVG formatted logo'],
    marketingText:
      'This DigiCert Common Mark VMC enables your validated logo for email recipients to identify that you’re a trusted & verified sender. Marketing teams leverage the ability to certify email to take back control of the inbox once and for all. With this Common Mark VMC, you’ll replace the generic initials in the avatar slot with your verified logo. To do this, you’ll need your IT team to enforce stricter email security to unlock BIMI for your domain(s). They’ll first need to set up SPF, DKIM and enforce DMARC policies to ensure proper email delivery. Once complete, everyone will be on their way to reducing spoofing & phishing attacks, elevating your brand and protecting your customers and prospects like never before. The advantages of verified mark certificates are undeniable. Here are some product features:',
    marketingBullets: [
      'VMC type: Common Mark',
      'Display verified company logo',
      '1 verified logo per certificate',
      'DigiCert hosted SVG logo (optional)',
      'Add up to 250 domains (extra fee per domain)',
      'DMARC required: p=reject or quarantine at 100%',
      'BIMI-compliant certificate',
      'High-assurance business validation included',
      'Supported by Gmail, Microsoft, Yahoo, etc.',
      'Unlimited certificate reissues',
      'Certificate management portal included',
      '24/7/365 support',
    ],
    marketingCallout1Title: 'Increase in Open Rates',
    marketingCallout1Text:
      'By showcasing your authenticity, you’ll create more trust. More trust in your customer’s email experience equals more opens. Studies show more than a 21% increase in open rates with Mark Certificates.',
    marketingCallout2Title: 'Increase in Brand Recognition',
    marketingCallout2Text:
      'Capitalizing on the avatar real estate to display your brand brings a significant increase in brand recognition. Studies show more than an 18% increase in brand recall after just a five-second exposure.',
    marketingCallout3Title: 'Increase in Email Deliverability',
    marketingCallout3Text:
      'Getting a VMC means you implemented the best email security practices. This can result in a 10% boost in email deliverability. Which simply means more opens and recognition.',
    landing: {
      subtext:
        'Showcase your logo on every email you send. With our Verified Mark Certificate (VMC) from DigiCert, you’ll be able to create a trusted email & immersive brand experience that converts more business. Adding a VMC is the final step to maximizing your DMARC enforcement to protect against phishing while boosting brand recognition and trust within the most popular mailboxes. Your customers deserve to know they’re receiving authentic email communications from you—VMC does exactly that! And Brandymail™ is here to fast-track it.',
      marketingText:
        'This DigiCert Common Mark VMC enables your validated logo for email recipients to identify that you’re a trusted & verified sender. Marketing teams leverage the ability to certify email to take back control of the inbox once and for all. With this Common Mark VMC, you’ll replace the generic initials in the avatar slot with your verified logo. To do this, you’ll need your IT team to enforce stricter email security to unlock BIMI for your domain(s)...',
      marketingCallout4Title: 'Increase in Trust',
      marketingCallout4Text:
        'Align your brand with the global leader in digital trust. By relying on DigiCert to assert your identity, authenticity, and trust, you’ll be making a statement that only the #1 trust provider can say.',
    },
  },
  {
    id: 'common-mark-certificate',
    name: 'Common Mark Certificate',
    title: 'Common Mark Certificate',
    optionText: 'My logo is NOT trademarked, but I’ve used it for at least 1 year',
    abbreviation: 'CMC',
    abbrev: 'cmc',
    type: 'certificate',
    shortDescription: 'CMC Type: Prior Use Mark',
    digiCertType: 'mark_certificate',
    digiCertEndpoint: 'mark_certificate',
    subtext:
      'Showcase your logo on every email you send. With our Verified Mark Certificate (VMC) from DigiCert, you’ll be able to create a trusted email & immersive brand experience that converts more business. Adding a VMC is the final step to maximizing your DMARC enforcement to protect against phishing while boosting brand recognition and trust within the most popular mailboxes. Your customers deserve to know they’re receiving authentic email communications from you—VMC does exactly that! And Brandymail™ is here to fast-track it.',
    bullets: [
      'Increase email open rates',
      'Boost email engagement',
      'Enhance brand experience',
      'Protect against phishing',
      'Improve email delivery',
      'Increase purchase likelihood',
      'Add credibility to emails',
      'Differentiate from competitors',
      'Distance yourself from bad-actors',
    ],
    description:
      'A CMC will display the logo of your company in popular inboxes and does not require a registered trademark.',
    learnMoreLink: '/products/digicert-common-mark-certificate',
    basePricePerDomain: 1188,
    additionalDomainPrice: 1188,
    discountOffBase: 0.1,
    discountOffSAN: 0.1,
    billingPeriod: 'year',
    msrp: 1188,
    yearMultipliers: [1, 1.9, 2.75],
    image: '/images/BM-Icon-CMC.png',
    icon: '/images/bm-symbol-with-no-check.svg',
    features: [
      { name: 'Logo', type: 'counter', default: 1 },
      { name: 'Domain', type: 'counter', default: 1 },
      { name: 'Year', type: 'counter', default: 1, max: 3 },
      'DigiCert Common Mark Certificate',
      'Premium Support',
      'Management Portal',
      'Premium Tools',
    ],
    forWho: [
      'Any company with a prior use logo',
      'A company wanting to use a modified version of their trademarked logo',
    ],
    required: ['Logo in use for at least 1 year', 'BIMI-compliant domain', 'SVG formatted logo'],
    marketingText:
      'This DigiCert Common Mark VMC enables your validated logo for email recipients to identify that you’re a trusted & verified sender. Marketing teams leverage the ability to certify email to take back control of the inbox once and for all. With this Common Mark VMC, you’ll replace the generic initials in the avatar slot with your verified logo. To do this, you’ll need your IT team to enforce stricter email security to unlock BIMI for your domain(s). They’ll first need to set up SPF, DKIM and enforce DMARC policies to ensure proper email delivery. Once complete, everyone will be on their way to reducing spoofing & phishing attacks, elevating your brand and protecting your customers and prospects like never before. The advantages of verified mark certificates are undeniable. Here are some product features:',
    marketingBullets: [
      'VMC type: Common Mark',
      'Display verified company logo',
      '1 verified logo per certificate',
      'DigiCert hosted SVG logo (optional)',
      'Add up to 250 domains (extra fee per domain)',
      'DMARC required: p=reject or quarantine at 100%',
      'BIMI-compliant certificate',
      'High-assurance business validation included',
      'Supported by Gmail, Microsoft, Yahoo, etc.',
      'Unlimited certificate reissues',
      'Certificate management portal included',
      '24/7/365 support',
    ],
    marketingCallout1Title: 'Increase in Open Rates',
    marketingCallout1Text:
      'By showcasing your authenticity, you’ll create more trust. More trust in your customer’s email experience equals more opens. Studies show more than a 21% increase in open rates with Mark Certificates.',
    marketingCallout2Title: 'Increase in Brand Recognition',
    marketingCallout2Text:
      'Capitalizing on the avatar real estate to display your brand brings a significant increase in brand recognition. Studies show more than an 18% increase in brand recall after just a five-second exposure.',
    marketingCallout3Title: 'Increase in Email Deliverability',
    marketingCallout3Text:
      'Getting a VMC means you implemented the best email security practices. This can result in a 10% boost in email deliverability. Which simply means more opens and recognition.',
    landing: {
      subtext:
        'I’m sure we can all agree, inboxes are complete chaos these days! Luckily, Verified Mark Certificates (VMC) are here to give us our sanity back. With a VMC for Common Marks, you can now showcase your logo every email you send to increase open rates.',
      subtext2:
        'With our VMCs from DigiCert, you’ll be able to create a trusted email & immersive brand experience that simply converts more business. Adding a VMC protects against phishing while boosting brand recognition and trust within popular mailboxes. Your customers deserve to know they’re receiving authentic email communications from you—VMC does exactly that! Since you’ve had your logo for more than a year, we’ll get you through validation so you can deliver a more trusted & personalized experience for all. And Brandymail™ is here to fast-track it and help you navigate the complexities of the process.',
      marketingText:
        'This DigiCert Common Mark VMC enables your validated logo for email recipients to identify that you’re a trusted & verified sender. Marketing teams leverage the ability to certify email to take back control of the inbox once and for all. With this Common Mark VMC, you’ll replace the generic initials in the avatar slot with your verified logo. To do this, you’ll need your IT team to enforce stricter email security to unlock BIMI for your domain(s)...',
      marketingCallout4Title: 'Increase in Trust',
      marketingCallout4Text:
        'Align your brand with the global leader in digital trust. By relying on DigiCert to assert your identity, authenticity, and trust, you’ll be making a statement that only the #1 trust provider can say.',
    },
  },
  {
    id: 'government-mark-certificate',
    name: 'Government Mark Certificate',
    title: 'Government Mark Certificate',
    optionText: 'My logo is for a government agency',
    abbreviation: 'GMC',
    abbrev: 'gmc',
    type: 'certificate',
    shortDescription: 'VMC Type: Government Mark',
    digiCertType: 'government_mark',
    digiCertEndpoint: 'vmc_basic',
    slug: 'digicert-government-mark-certificate',
    subtext:
      'Governments can now get a Verified Mark Certificate (VMC) showing their marks or logos authorized under applicable law—a locally registered trademark is no longer required. Government agencies in eligible locales can display its logo and a verified blue checkmark on every email sent. With our Verified Mark Certificate (VMC) from DigiCert, they will be able to create a trusted email & immersive communications that get read more often. Adding a VMC is the final step to maximizing DMARC enforcement to protect against phishing while boosting authenticity and trust within the most popular mailboxes. Citizens deserve to know they’re receiving authentic email communications from any government agency—VMC does exactly that! Since the logo is for a legislative government, validation should be easy. And Brandymail™ is here to fast-track it.',
    bullets: [
      'Increase email open rates',
      'Boost email engagement',
      'Enhance communication',
      'Protect against phishing',
      'Improve email delivery',
      'Increase purchase likelihood',
      'Add credibility to emails',
      'Differentiate from competitors',
      'Distance yourself from bad-actors',
    ],
    description:
      'A GMC will display the logo of your company in popular inboxes and a blue checkmark in Gmail for officially registered trademarks or government agencies.',
    learnMoreLink: '/products/digicert-government-mark-certificate',
    basePricePerDomain: 1608,
    additionalDomainPrice: 1608,
    discountOffBase: 0.1,
    discountOffSAN: 0.1,
    billingPeriod: 'year',
    msrp: 1608,
    yearMultipliers: [1, 1.9, 2.75],
    image: '/images/BM-Icon-GMC.png',
    features: [
      { name: 'Logo', type: 'counter', default: 1 },
      { name: 'Domain', type: 'counter', default: 1 },
      { name: 'Year', type: 'counter', default: 1, max: 3 },
      'DigiCert Verified Mark Certificate',
      'Verified Blue Checkmark in Gmail',
      'Premium Support',
      'Management Portal',
      'Premium Tools',
    ],
    forWho: ['Any company with a trademarked logo', 'Government agencies'],
    required: [
      'Logo is a registered trademark or government seal',
      'BIMI-compliant domain',
      'SVG formatted logo',
    ],
    marketingText:
      'This DigiCert Government Mark VMC enables all the visual cues for email recipients to quickly identify trusted & verified senders. Mass communication & PR teams leverage the ability to certify email to cut through noise in the inbox. With this Government Mark VMC, the generic initials in the avatar slot will be replaced with the authorized government mark or logo and the blue checkmark will activate. To do this, the IT team will need to enforce stricter email security to unlock BIMI for email sending domain(s). They’ll first need to set up SPF, DKIM and enforce DMARC policies to ensure proper email delivery. Once complete, this will reduce impersonation & fraud, spoofing & phishing attacks, increase integrity and protect citizens like never before. The advantages of verified mark certificates are undeniable. Here are some product features:',
    marketingBullets: [
      'VMC type: Government Mark',
      'Display government mark or logo',
      'Eligible countries or regions',
      'Activate blue checkmark in Gmail',
      '1 verified logo per certificate',
      'DigiCert hosted SVG logo (optional)',
      'Add up to 250 domains (extra fee per domain)',
      'DMARC required: p=reject or quarantine at 100%',
      'BIMI-compliant certificate',
      'High-assurance business validation included',
      'Supported by Gmail, Microsoft, Yahoo, etc.',
      'Unlimited certificate reissues',
      'Certificate management portal included',
      '24/7/365 support',
    ],
    marketingCallout1Title: 'Increase in Open Rates',
    marketingCallout1Text:
      'By showcasing your authenticity, you’ll create more trust. More trust in your customer’s email experience equals more opens. Studies show more than a 21% increase in open rates with Mark Certificates.',
    marketingCallout2Title: 'Increase in Brand Recognition',
    marketingCallout2Text:
      'Capitalizing on the avatar real estate to display your brand brings a significant increase in brand recognition. Studies show more than an 18% increase in brand recall after just a five-second exposure.',
    marketingCallout3Title: 'Increase in Email Deliverability',
    marketingCallout3Text:
      'Getting a VMC means you implemented the best email security practices. This can result in a 10% boost in email deliverability. Which simply means more opens and recognition.',
    landing: {
      subtext:
        'I’m sure we can all agree, inboxes are complete chaos these days! Luckily, Verified Mark Certificates (VMC) are here to give us our sanity back. With a VMC for Government Marks, you can now showcase your logo and a blue checkmark on every email you send to increase authenticity, open rates and awareness.',
      subtext2:
        'With our VMCs from DigiCert, you’ll be able to create a trusted email & immersive brand experience that simply gets your message to more people. Adding a VMC protects against phishing while boosting assurance and trust within popular mailboxes. Your recipients deserve to know they’re receiving authentic email communications from you—VMC does exactly that! Since your logo is a government mark, you’ll breeze through logo validation to quickly deliver a more trusted & personalized experience for all. And Brandymail™ is here to fast-track it and help you navigate the complexities of the process.',
      marketingText:
        'This DigiCert Government Mark VMC enables all the visual cues for email recipients to quickly identify that you’re a trusted & verified sender. Communication teams leverage the ability to certify email to take back control of the inbox once and for all. With this Government Mark VMC, you’ll replace the generic initials in the avatar slot with your verified government agency logo and add the coveted blue checkmark...',
      marketingCallout4Title: 'Increase in Trust',
      marketingCallout4Text:
        'Align your brand with the global leader in digital trust. By relying on DigiCert to assert your identity, authenticity, and trust, you’ll be making a statement that only the #1 trust provider can say.',
    },
  },
];

export const getProductsByType = (type) => products.filter((p) => p.type === type);
export const getProductById = (id) => products.find((p) => p.id === id);
export const calculatePrice = (product, options) => {
  if (!product || !options) {
    console.error('Invalid product or options:', { product, options });
    return {
      msrp: 0,
      msrpFormatted: '$0',
      ourPrice: 0,
      monthlyPrice: 0,
      annualPrice: 0,
      total: 0,
    };
  }

  const { domain = 1, logo = 1, year = 1 } = options;
  const {
    basePricePerDomain,
    additionalDomainPrice,
    discountOffBase,
    discountOffSAN,
    yearMultipliers,
  } = product;

  const yearMultiplier = yearMultipliers[year - 1] || 1;

  let msrp = basePricePerDomain * year + (domain - 1) * additionalDomainPrice * year;
  let ourPriceOneYear = basePricePerDomain * (1 - discountOffBase);
  if (domain > 1) {
    ourPriceOneYear += (domain - 1) * additionalDomainPrice * (1 - discountOffSAN);
  }

  let ourPrice = ourPriceOneYear * yearMultiplier;

  msrp *= logo;
  ourPrice *= logo;

  const annualPrice = ourPrice / year;
  const monthlyPrice = ourPrice / (12 * year);

  return {
    msrp: Math.floor(msrp),
    msrpFormatted: `$${Math.floor(msrp).toLocaleString()}`,
    ourPrice,
    monthlyPrice,
    annualPrice,
    total: ourPrice,
  };
};

export const getSpecificProducts = (...productIds) =>
  products.filter((product) => productIds.includes(product.id));
