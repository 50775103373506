export const dmarcProducts = [
  {
    id: 'dmarc-plus',
    name: 'Plus',
    title: 'DMARC Plus',
    abbreviation: 'Plus',
    type: 'dmarc',
    shortDescription: 'Self-service DMARC & BIMI configuration',
    image: '/images/BM-Icon-DMARC-Plus.png',
    description:
      'Easily configure DMARC & BIMI in a self-service manner to quickly benefit from VMCs.',
    bullets: ['2 Domains', '3 Months Data History', '1 Invited User'],
    options: [
      {
        emails: '100,000',
        price: 35.99,
        msrp: 44.99,
        billingPeriod: 'monthly',
        annualPrice: 431.88,
      },
      {
        emails: '500,000',
        price: 59.99,
        msrp: 74.99,
        billingPeriod: 'monthly',
        annualPrice: 719.88,
      },
      {
        emails: '1,000,000',
        price: 111.99,
        msrp: 139.99,
        billingPeriod: 'monthly',
        annualPrice: 1343.88,
      },
    ],
  },
  {
    id: 'dmarc-premium',
    name: 'Premium',
    title: 'DMARC Premium',
    abbreviation: 'Premium',
    type: 'dmarc',
    shortDescription: 'Advanced DMARC enforcement with team collaboration',
    image: '/images/BM-Icon-DMARC-Premium.png',
    description:
      'Enforce DMARC policies with colleagues in a user-friendly interface & leverage BIMI protocols to display verified logos.',
    bullets: ['4 Domains', '1 Year Data History', 'Unlimited Invited Users'],
    options: [
      {
        emails: '100,000',
        price: 71.99,
        msrp: 89.99,
        billingPeriod: 'monthly',
        annualPrice: 863.88,
      },
      {
        emails: '500,000',
        price: 111.99,
        msrp: 139.99,
        billingPeriod: 'monthly',
        annualPrice: 1343.88,
      },
      {
        emails: '1,000,000',
        price: 191.99,
        msrp: 239.99,
        billingPeriod: 'monthly',
        annualPrice: 2303.88,
      },
      {
        emails: '5,000,000',
        price: 299.99,
        msrp: 374.99,
        billingPeriod: 'monthly',
        annualPrice: 3599.88,
      },
    ],
  },
];

export function calculateDmarcPrice(product, options) {
  // Add null checks
  const emailFeature = product.features?.find((f) => f.name === 'Emails') || {};
  const emailOptions = emailFeature.options || [];
  const selectedOption =
    emailOptions.find((o) => o.value === options.selectedOption) || emailOptions[0];

  return {
    msrp: selectedOption?.msrp || 0,
    msrpFormatted: `$${(selectedOption?.msrp || 0).toFixed(2)}`,
    price: selectedOption?.price || 0,
    annualPrice: selectedOption?.annualPrice || 0,
    total: selectedOption?.annualPrice || 0,
  };
}
