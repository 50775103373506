import Image from 'next/image';
import Link from 'next/link';
import SubscribeForm from '../../app/Common/SubscribeForm';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-wrapper">
      <div className="container-default w-container">
        <div className="footer-top">
          <div
            className="w-layout-grid grid-footer-2-column---logo-and-form"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div className="inner-container _467px">
              <div className="text-center---tablet">
                <Link href="/" className="footer-logo-wrapper w-inline-block">
                  <Image
                    src="/images/logo.svg"
                    alt="Logo - Brandymail.com"
                    className="footer-logo"
                    height={50}
                    width={150}
                    priority
                  />
                </Link>
                <p className="color-neutral-600 mg-bottom-0 paragraph-medium">
                  Brandymail™ enhances the latest email security solutions to help the world{' '}
                  <em>Trust Email Again</em>. Our experts fast-track the issuance & management of
                  Mark Certificates, BIMI &amp; DMARC enforcement to ensure next-gen email trust and
                  cohesive brand experiences.
                </p>
              </div>
            </div>
            <SubscribeForm />
          </div>
        </div>
        <div className="footer-middle">
          <div className="menu-main-wrapper">
            <div className="menu-wrapper">
              <div className="menu-title-wrapper dark-border">
                <div className="text-300 bold color-neutral-100">Main Pages</div>
              </div>
              <div className="nav-content">
                <ul className="list-nav">
                  <li className="list-nav-item">
                    <Link href="/" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li className="list-nav-item">
                    <Link href="/products/digicert-verified-mark-certificate" className="nav-link">
                      Products
                    </Link>
                  </li>
                  <li className="list-nav-item">
                    <Link href="/how-it-works" className="nav-link">
                      How It Works
                    </Link>
                  </li>
                  <li className="list-nav-item">
                    <Link href="/about" className="nav-link">
                      About
                    </Link>
                  </li>
                  <li className="list-nav-item">
                    <Link href="/contact" className="nav-link">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="menu-wrapper contact">
              <div className="menu-title-wrapper dark-border">
                <div className="text-300 bold color-neutral-100">Contact us</div>
              </div>
              <div className="column-contact-link-wrapper">
                <Link
                  href="mailto:support@brandymail.com"
                  className="column-contact-link w-inline-block"
                >
                  <div className="line-rounded-icon column-contact-link-icon-left"></div>
                  <div>
                    <div className="text-200 color-neutral-100 mg-bottom-4px">Email</div>
                    <div className="text-200 medium">support@brandymail.com</div>
                  </div>
                </Link>
              </div>
              <div className="column-contact-link-wrapper last">
                <Link href="tel:(414)687-5892" className="column-contact-link w-inline-block">
                  <div className="line-rounded-icon column-contact-link-icon-left"></div>
                  <div>
                    <div className="text-200 color-neutral-100 mg-bottom-4px">Phone</div>
                    <div className="text-200 medium">(414) 687-5892</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div
            className="w-layout-grid grid-footer-paragrah---social-media"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <p className="color-neutral-600 mg-bottom-0">
              Copyright &copy; {currentYear} Brandymail.com. All rights reserved. |{' '}
              <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                Privacy
              </Link>{' '}
              |{' '}
              <Link href="/terms" target="_blank" rel="noopener noreferrer">
                Terms
              </Link>
              |{' '}
              <Link href="/refund-policy" target="_blank" rel="noopener noreferrer">
                Refund Policy
              </Link>{' '}
            </p>
            {/* <div className="w-layout-grid social-media-grid-top footer-social-icon-links">
              <Link
                href="https://facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-link-wrapper white accent w-inline-block"
              >
                <div className="social-icon-font"></div>
              </Link>
              <Link
                href="https://twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-link-wrapper white accent w-inline-block"
              >
                <div className="social-icon-font"></div>
              </Link>
              <Link
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-link-wrapper white accent w-inline-block"
              >
                <div className="social-icon-font"></div>
              </Link>
              <Link
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-link-wrapper white accent w-inline-block"
              >
                <div className="social-icon-font"></div>
              </Link>
              <Link
                href="http://youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-link-wrapper white accent w-inline-block"
              >
                <div className="social-icon-font"></div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
