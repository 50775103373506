'use client';
import { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';
import Header from './Header';

const HeaderWrapper = () => {
  const pathname = usePathname();
  const { data: session } = useSession();
  const [initialIsWhiteBackground, setInitialIsWhiteBackground] = useState(false);

  useEffect(() => {
    // Ensure pathname is defined
    if (!pathname) return;

    const whiteBackgroundPaths = [
      '/about',
      '/bimi-checker',
      '/bimi-visualizer',
      '/bimi-logo-validator',
      '/bimi-logo-ai-wizard',
      '/dmarc-inspector',
      '/vmc-validator',
      '/faq',
      '/faqs',
      '/case-studies',
      '/guides',
      '/industry-news',
    ];

    // Check if the current path starts with any of these prefixes
    const whiteBackgroundPrefixes = [
      '/articles',
      '/help-center',
      '/glossary',
      '/case-studies',
      '/guides',
      '/industry-news',
      '/industryNews',
      '/faq',
      '/faqs',
      '/caseStudies',
    ];

    // Normalize the pathname to handle trailing slashes consistently
    const normalizedPath = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;

    const shouldHaveWhiteBackground =
      whiteBackgroundPaths.includes(normalizedPath) ||
      whiteBackgroundPrefixes.some(
        (prefix) => normalizedPath === prefix || normalizedPath.startsWith(`${prefix}/`)
      );

    // Debug log
    console.log('HeaderWrapper path check:', {
      pathname,
      normalizedPath,
      shouldHaveWhiteBackground,
      matchedExact: whiteBackgroundPaths.includes(normalizedPath),
      matchedPrefix: whiteBackgroundPrefixes.some(
        (prefix) => normalizedPath === prefix || normalizedPath.startsWith(`${prefix}/`)
      ),
    });

    setInitialIsWhiteBackground(shouldHaveWhiteBackground);
  }, [pathname]);

  return <Header session={session} initialIsWhiteBackground={initialIsWhiteBackground} />;
};

export default HeaderWrapper;
