'use client';
import { Analytics } from '@vercel/analytics/react';
import { usePathname } from 'next/navigation';
import Footer from './Common/Footer';
import FooterSimpleDark from './Common/FooterSimpleDark';
import FooterSmall from './Common/FooterSmall';
import HeaderWrapper from './Common/HeaderWrapper';

export default function RootLayout({ children }) {
  const pathname = usePathname();

  const pathsWithoutHeader = [
    '/auth/signup',
    '/auth/reseller-signup',
    '/auth/signin',
    '/auth/verify',
    '/auth/check-email',
    '/forgot-password',
    '/auth/reset-password',
    '/privacy-policy',
    '/terms',
    '/refund-policy',
  ];

  const pathsWithoutHeaderFooter = [
    '/landing/digicert-verified-mark-certificate',
    '/landing/digicert-common-mark-certificate',
    '/landing/digicert-government-mark-certificate',
    '/landing/entrust-to-digicert-vmc',
  ];

  const isHeaderHidden =
    pathsWithoutHeader.includes(pathname) || pathsWithoutHeaderFooter.includes(pathname);
  const isFooterHidden = pathsWithoutHeaderFooter.includes(pathname);
  const isAccountPage = pathname?.startsWith('/account/');
  const isEnrollmentPage = pathname?.startsWith('/digicert-enrollment');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      {!isHeaderHidden && <HeaderWrapper />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 auto',
          paddingTop: isAccountPage ? '112px' : 0, // Only add padding for account pages
        }}
      >
        {children}
      </div>
      <Analytics />
      {!isFooterHidden && !isHeaderHidden ? (
        isAccountPage || isEnrollmentPage ? (
          <FooterSmall />
        ) : (
          <Footer />
        )
      ) : isFooterHidden ? (
        <FooterSimpleDark />
      ) : (
        <FooterSmall />
      )}
    </div>
  );
}
