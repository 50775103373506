import Image from 'next/image';
import Link from 'next/link';
import SubscribeForm from './SubscribeForm';

const FooterSimpleDark = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-wrapper">
      <div className="container-default w-container">
        <div className="footer-top">
          <div
            className="w-layout-grid grid-footer-2-column---logo-and-form"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div className="inner-container _467px">
              <div className="text-center---tablet">
                <Link href="/" className="footer-logo-wrapper w-inline-block" target="_blank">
                  <Image
                    src="/images/logo.svg"
                    alt="Logo - Brandymail.com"
                    className="footer-logo"
                    height={50}
                    width={150}
                    priority
                  />
                </Link>
                {/* <p className="color-neutral-600 mg-bottom-0">
                  Brandymail™ offers the latest email security solutions to help the world{' '}
                  <em>Trust Email Again</em>. Our experts will fast-track your BIMI &amp; DMARC set
                  up to deliver next-gen email trust and cohesive brand experience.
                </p> */}
              </div>
            </div>
            {/* <SubscribeForm /> */}

            <div className="inner-container width-100">
              <div className="form-button-inside-block w-form" style={{ textAlign: 'right' }}>
                Copyright &copy; {currentYear} Brandymail.com. All rights reserved.
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="w-layout-grid grid-footer-paragrah---social-media">
            <p className="color-neutral-600 mg-bottom-0">
              Copyright &copy; {currentYear} Brandymail.com. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterSimpleDark;
