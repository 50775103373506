import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Button } from 'antd';

const bannerStyle = {
  position: 'fixed',
  bottom: '16px',
  right: '16px',
  width: '370px',
  backgroundColor: '#fff',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  padding: '16px',
  borderRadius: '8px',
  zIndex: 1000,
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
};

const bannerVisibleStyle = {
  transform: 'translateY(0)',
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '12px',
  marginTop: '16px',
};

const CookieConsent = ({ onConsentChange }) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if the cookie_consent is already set
    const consent = Cookies.get('cookie_consent');
    if (!consent) {
      // No consent given yet, show banner
      setShowBanner(true);
    } else if (onConsentChange) {
      onConsentChange(consent);
    }
  }, [onConsentChange]);

  const handleConsent = (value) => {
    // Set cookie for one year
    Cookies.set('cookie_consent', value, { expires: 365 });
    if (onConsentChange) {
      onConsentChange(value);
    }
    // Hide banner
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div style={{ ...bannerStyle, ...(showBanner ? bannerVisibleStyle : {}) }}>
      <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '8px' }}>
        Your Privacy, Our Priority
      </div>
      <div style={{ fontSize: '14px', lineHeight: '1.3' }}>
        Our website uses cookies to deliver a tailored browsing experience—enhancing page speed,
        remembering your preferences, and offering content that matters to you. Accept cookies to
        enjoy a smoother, more personalized experience.
      </div>
      <div style={buttonContainerStyle}>
        <Button type="default" onClick={() => handleConsent('declined')}>
          Essential Cookies Only
        </Button>
        <Button type="primary" onClick={() => handleConsent('accepted')}>
          Accept All Cookies
        </Button>
      </div>
    </div>
  );
};

export default CookieConsent;
